<template>
  <div>
    <el-form
      label-position="left"
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      style="width: 350px; margin-top: 20px; margin-left: 50px"
      label-width="100px"
    >
      <el-form-item label="原密码" prop="password">
        <el-input type="password" v-model="dataForm.password"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input type="password" v-model="dataForm.newPassword"></el-input>
      </el-form-item>
      <el-form-item label="再次输入" prop="confirmPassword">
        <el-input type="password" v-model="dataForm.confirmPassword"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="dataFormSubmit()">确定修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Qs from "qs";
import MD5 from "js-md5";

import { clearLoginInfo } from "@/utils";
export default {
  data() {
    var validateConfirmPassword = (rule, value, callback) => {
      if (this.dataForm.newPassword !== value) {
        callback(new Error("确认密码与新密码不一致"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      dataForm: {
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
      dataRule: {
        password: [
          {
            required: true,
            message: "原密码不能为空",
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "新密码不能为空",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "确认密码不能为空",
            trigger: "blur",
          },
          {
            validator: validateConfirmPassword,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    userName: {
      get() {
        return this.$store.state.user.name;
      },
    },
    mainTabs: {
      get() {
        return this.$store.state.common.mainTabs;
      },
      set(val) {
        this.$store.commit("common/updateMainTabs", val);
      },
    },
  },
  methods: {
    // 表单提交
    dataFormSubmit() {
      let data = {
        oldPassword:MD5(this.dataForm.password) ,
        newPassword: MD5(this.dataForm.newPassword),
      };
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (this.dataForm.newPassword != this.dataForm.confirmPassword) {
            this.$message.error("两次输入的新密码不一致！");
            return;
          }
          this.$http({
            url: this.$http.adornUrl("/organBusiness/updatePwsd"),
            method: "post",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              token: this.$cookie.get("token"),
            },
            data: Qs.stringify(data),
          }).then(({ data }) => {
            if (data.status) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.dialogVisible = false;
                 if(sessionStorage.loginType=='zdLogin'){
                 				  this.$router.push({
                 				    name: "zdLogin",
                 				  });
                 }else{
                 				  this.$router.push({
                 				    name: "login",
                 				  });
                 }
                },
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    },
  },
};
</script>
